import { Icon } from "@chakra-ui/react"

export function Parcoursup(props) {
  return (
    <Icon viewBox="0 0 396.9 141.7" {...props}>
      <path
        fill="#3D566E"
        d="M88.3,79.4c-1.8,1.8-4.7,2.9-7.9,2.9c-3.3,0-5.7-0.9-7.8-3.2v14.7h-8.1V49.6h7.8v3c2.2-2.5,4.7-3.4,8.1-3.4  c3.2,0,6.1,1.1,7.9,2.9c3.2,3.2,3.3,8.8,3.3,13.6C91.6,70.6,91.5,76.2,88.3,79.4z M78,56.5c-4.8,0-5.5,4-5.5,9.3  c0,5.3,0.6,9.3,5.5,9.3s5.5-4,5.5-9.3C83.5,60.5,82.9,56.5,78,56.5z"
      />
      <path
        fill="#3D566E"
        d="M115.8,81.9v-2.8c-2.2,2.2-4.2,3.1-7.9,3.1c-3.7,0-6.3-0.9-8.3-2.9C97.9,77.6,97,75,97,72.1  c0-5.2,3.5-9.4,11.1-9.4h7.6v-1.6c0-3.5-1.7-5.1-6-5.1c-3.1,0-4.5,0.7-6.2,2.7l-5.2-5c3.2-3.5,6.3-4.5,11.7-4.5  c9.1,0,13.8,3.8,13.8,11.4v21.3H115.8z M115.6,68.3h-6.4c-2.9,0-4.5,1.4-4.5,3.7c0,2.2,1.5,3.7,4.7,3.7c2.2,0,3.7-0.2,5.1-1.6  c0.9-0.8,1.2-2.1,1.2-4.1V68.3z"
      />
      <path fill="#3D566E" d="M150,58.4c-1.2-1.2-2.3-1.9-4.3-1.9c-2.5,0-5.2,1.9-5.2,6v19.5h-8.1V49.6h7.9v3.1c1.6-1.9,4.7-3.5,8.1-3.5  c3.2,0,5.4,0.8,7.6,3L150,58.4z" />
      <path
        fill="#3D566E"
        d="M171.6,82.3c-6.5,0-14.5-3.5-14.5-16.6c0-13.1,8-16.5,14.5-16.5c4.5,0,7.8,1.4,10.7,4.3l-5.5,5.5  c-1.7-1.8-3.1-2.5-5.2-2.5c-1.9,0-3.4,0.7-4.6,2.1c-1.2,1.6-1.8,3.7-1.8,7.1c0,3.4,0.6,5.7,1.8,7.2c1.2,1.4,2.7,2.1,4.6,2.1  c2.1,0,3.5-0.7,5.2-2.5l5.5,5.4C179.4,80.9,176.1,82.3,171.6,82.3z"
      />
      <path
        fill="#3D566E"
        d="M209.8,78.3c-2,2.1-5.3,4-10.1,4s-8-1.9-10-4c-2.9-3-3.7-6.7-3.7-12.6c0-5.8,0.7-9.5,3.7-12.5c2-2.1,5.2-4,10-4  s8.1,1.9,10.1,4c2.9,3,3.7,6.7,3.7,12.5C213.4,71.6,212.7,75.3,209.8,78.3z M203.5,57.9c-0.9-0.9-2.2-1.4-3.8-1.4s-2.8,0.5-3.7,1.4  c-1.7,1.7-1.9,4.5-1.9,7.8c0,3.4,0.2,6.2,1.9,7.9c0.9,0.9,2.1,1.4,3.7,1.4s2.9-0.5,3.8-1.4c1.7-1.7,1.9-4.5,1.9-7.9  C205.4,62.4,205.2,59.6,203.5,57.9z"
      />
      <path
        fill="#3D566E"
        d="M239.7,81.9v-3c-2.1,2.2-5.1,3.4-8.1,3.4c-3.2,0-5.8-1.1-7.6-2.9c-2.6-2.6-3.3-5.6-3.3-9.2V49.6h8.1v19.6  c0,4.4,2.8,5.9,5.3,5.9c2.5,0,5.4-1.5,5.4-5.9V49.6h8.1v32.3H239.7z"
      />
      <path fill="#3D566E" d="M274.3,58.4c-1.2-1.2-2.3-1.9-4.3-1.9c-2.5,0-5.2,1.9-5.2,6v19.5h-8.1V49.6h7.9v3.1c1.6-1.9,4.7-3.5,8.1-3.5  c3.2,0,5.4,0.8,7.6,3L274.3,58.4z" />
      <path
        fill="#FF3333"
        d="M334,81.9v-3c-2.1,2.2-5.1,3.4-8.1,3.4c-3.2,0-5.8-1.1-7.6-2.9c-2.6-2.6-3.3-5.6-3.3-9.2V49.6h8.1v19.6  c0,4.4,2.8,5.9,5.3,5.9c2.5,0,5.4-1.5,5.4-5.9V49.6h8.1v32.3H334z"
      />
      <path
        fill="#FF3333"
        d="M374.8,79.4c-1.8,1.8-4.7,2.9-7.9,2.9c-3.3,0-5.7-0.9-7.8-3.2v14.7H351V49.6h7.8v3c2.2-2.5,4.7-3.4,8.1-3.4  c3.2,0,6.1,1.1,7.9,2.9c3.2,3.2,3.3,8.8,3.3,13.6C378.1,70.6,378,76.2,374.8,79.4z M364.5,56.5c-4.8,0-5.5,4-5.5,9.3  c0,5.3,0.6,9.3,5.5,9.3s5.5-4,5.5-9.3C370,60.5,369.4,56.5,364.5,56.5z"
      />
      <g>
        <path fill="#3D566E" d="M91,101.6v-10h6.4V93h-4.9v2.9h4.1v1.3h-4.1v3h4.9v1.4H91z" />
        <path
          fill="#3D566E"
          d="M103.8,101.6v-4.3c0-1.1-0.6-1.6-1.4-1.6s-1.5,0.5-1.5,1.6v4.3h-1.4v-7.1h1.4v0.7c0.5-0.5,1.2-0.8,1.9-0.8   c0.7,0,1.3,0.2,1.8,0.6c0.5,0.5,0.7,1.2,0.7,2v4.5H103.8z"
        />
        <path fill="#3D566E" d="M109.6,101.6c-1.3,0-1.9-1-1.9-2v-3.9h-0.8v-1.1h0.8v-2.2h1.4v2.2h1.4v1.1h-1.4v3.8c0,0.5,0.3,0.8,0.8,0.8h0.6   v1.2H109.6z" />
        <path fill="#3D566E" d="M116.4,96.2c-0.3-0.3-0.6-0.5-1.1-0.5c-0.8,0-1.4,0.6-1.4,1.6v4.3h-1.4v-7.1h1.4v0.8c0.4-0.5,1.1-0.9,1.9-0.9   c0.7,0,1.2,0.2,1.7,0.7L116.4,96.2z" />
        <path
          fill="#3D566E"
          d="M119.3,98.5c0,1.2,0.6,2,1.8,2c0.8,0,1.2-0.2,1.7-0.7l0.9,0.9c-0.7,0.7-1.4,1.1-2.7,1.1   c-1.8,0-3.2-0.9-3.2-3.6c0-2.3,1.2-3.6,3-3.6c1.9,0,3,1.4,3,3.4v0.6H119.3z M122.3,96.5c-0.2-0.5-0.7-0.9-1.4-0.9s-1.2,0.4-1.4,0.9   c-0.1,0.3-0.2,0.5-0.2,1h3.2C122.5,97,122.5,96.8,122.3,96.5z"
        />
        <path fill="#3D566E" d="M125.4,101.6v-1.1l3.5-4.7h-3.3v-1.3h5.1v1.1l-3.6,4.7h3.6v1.3H125.4z" />
        <path
          fill="#3D566E"
          d="M140.3,101.6v-0.8c-0.5,0.6-1.1,0.8-1.9,0.8c-0.7,0-1.3-0.2-1.7-0.6c-0.7-0.7-0.8-1.9-0.8-3   c0-1.1,0.1-2.3,0.8-3c0.4-0.4,1-0.6,1.7-0.6c0.8,0,1.3,0.2,1.9,0.8v-3.6h1.4v10H140.3z M138.8,95.7c-1.3,0-1.5,1.1-1.5,2.4   s0.2,2.4,1.5,2.4c1.3,0,1.5-1.1,1.5-2.4S140,95.7,138.8,95.7z"
        />
        <path
          fill="#3D566E"
          d="M147.9,101.6v-0.6c-0.5,0.5-1,0.7-1.9,0.7s-1.5-0.2-1.9-0.6c-0.4-0.4-0.6-0.9-0.6-1.5c0-1.2,0.8-2,2.5-2h1.9   V97c0-0.9-0.4-1.3-1.6-1.3c-0.8,0-1.2,0.2-1.6,0.7l-0.9-0.9c0.7-0.8,1.4-1,2.5-1c1.9,0,2.9,0.8,2.9,2.4v4.7H147.9z M147.9,98.5   h-1.7c-0.8,0-1.3,0.4-1.3,1s0.4,1,1.3,1c0.5,0,1,0,1.3-0.4c0.2-0.2,0.3-0.5,0.3-1.1V98.5z"
        />
        <path
          fill="#3D566E"
          d="M155.9,101.6v-4.3c0-1.1-0.6-1.6-1.4-1.6s-1.5,0.5-1.5,1.6v4.3h-1.4v-7.1h1.4v0.7c0.5-0.5,1.2-0.8,1.9-0.8   c0.7,0,1.3,0.2,1.8,0.6c0.5,0.5,0.7,1.2,0.7,2v4.5H155.9z"
        />
        <path
          fill="#3D566E"
          d="M162,101.7c-1.2,0-2.1-0.2-3-1l0.9-0.9c0.6,0.6,1.3,0.7,2,0.7c0.9,0,1.6-0.3,1.6-1c0-0.5-0.3-0.8-1-0.8   l-1.1-0.1c-1.3-0.1-2.1-0.7-2.1-2c0-1.4,1.2-2.2,2.7-2.2c1.1,0,2,0.2,2.6,0.8l-0.9,0.9c-0.4-0.4-1.1-0.5-1.8-0.5   c-0.9,0-1.3,0.4-1.3,0.9c0,0.4,0.2,0.7,1,0.8l1.1,0.1c1.3,0.1,2.1,0.7,2.1,2C165,100.9,163.7,101.7,162,101.7z"
        />
        <path fill="#3D566E" d="M172.2,101.6c-1.4,0-1.9-0.9-1.9-2v-8h1.4v7.9c0,0.5,0.2,0.8,0.8,0.8h0.6v1.2H172.2z" />
        <path fill="#3D566E" d="M173.9,94.4v-2.7h1.6v1.5L173.9,94.4z" />
        <path
          fill="#3D566E"
          d="M178.9,98.5c0,1.2,0.6,2,1.8,2c0.8,0,1.2-0.2,1.7-0.7l0.9,0.9c-0.7,0.7-1.4,1.1-2.7,1.1   c-1.8,0-3.2-0.9-3.2-3.6c0-2.3,1.2-3.6,3-3.6c1.9,0,3,1.4,3,3.4v0.6H178.9z M181.9,96.5c-0.2-0.5-0.7-0.9-1.4-0.9s-1.2,0.4-1.4,0.9   c-0.1,0.3-0.2,0.5-0.2,1h3.2C182.1,97,182.1,96.8,181.9,96.5z"
        />
        <path
          fill="#3D566E"
          d="M189.9,101.6v-4.3c0-1.1-0.6-1.6-1.4-1.6s-1.5,0.5-1.5,1.6v4.3h-1.4v-7.1h1.4v0.7c0.5-0.5,1.2-0.8,1.9-0.8   c0.7,0,1.3,0.2,1.8,0.6c0.5,0.5,0.7,1.2,0.7,2v4.5H189.9z"
        />
        <path
          fill="#3D566E"
          d="M196,101.7c-1.2,0-2.1-0.2-3-1l0.9-0.9c0.6,0.6,1.3,0.7,2,0.7c0.9,0,1.6-0.3,1.6-1c0-0.5-0.3-0.8-1-0.8   l-1.1-0.1c-1.3-0.1-2.1-0.7-2.1-2c0-1.4,1.2-2.2,2.7-2.2c1.1,0,2,0.2,2.6,0.8l-0.9,0.9c-0.4-0.4-1.1-0.5-1.8-0.5   c-0.9,0-1.3,0.4-1.3,0.9c0,0.4,0.2,0.7,1,0.8l1.1,0.1c1.3,0.1,2.1,0.7,2.1,2C198.9,100.9,197.7,101.7,196,101.7z"
        />
        <path
          fill="#3D566E"
          d="M201.8,98.5c0,1.2,0.6,2,1.8,2c0.8,0,1.2-0.2,1.7-0.7l0.9,0.9c-0.7,0.7-1.4,1.1-2.7,1.1   c-1.8,0-3.2-0.9-3.2-3.6c0-2.3,1.2-3.6,3-3.6c1.9,0,3,1.4,3,3.4v0.6H201.8z M204.8,96.5c-0.2-0.5-0.7-0.9-1.4-0.9   c-0.7,0-1.2,0.4-1.4,0.9c-0.1,0.3-0.2,0.5-0.2,1h3.2C205,97,205,96.8,204.8,96.5z"
        />
        <path fill="#3D566E" d="M208.4,93.1v-1.5h1.5v1.5H208.4z M208.5,101.6v-7.1h1.4v7.1H208.5z" />
        <path
          fill="#3D566E"
          d="M214.7,104.5c-1.1,0-1.8-0.3-2.5-0.9l0.9-0.9c0.4,0.4,0.8,0.6,1.5,0.6c1.2,0,1.7-0.9,1.7-1.8v-0.9   c-0.5,0.6-1.1,0.8-1.8,0.8c-0.7,0-1.3-0.2-1.7-0.6c-0.7-0.7-0.8-1.7-0.8-2.9c0-1.2,0.1-2.2,0.8-2.9c0.4-0.4,1-0.6,1.7-0.6   c0.8,0,1.3,0.2,1.9,0.8v-0.8h1.4v7.1C217.7,103.3,216.6,104.5,214.7,104.5z M214.9,95.7c-1.3,0-1.5,1.1-1.5,2.2   c0,1.1,0.2,2.2,1.5,2.2s1.4-1.1,1.4-2.2C216.3,96.8,216.1,95.7,214.9,95.7z"
        />
        <path
          fill="#3D566E"
          d="M224.5,101.6v-4.3c0-1.1-0.6-1.6-1.4-1.6c-0.8,0-1.5,0.5-1.5,1.6v4.3h-1.4v-7.1h1.4v0.7   c0.5-0.5,1.2-0.8,1.9-0.8c0.7,0,1.3,0.2,1.8,0.6c0.5,0.5,0.7,1.2,0.7,2v4.5H224.5z"
        />
        <path
          fill="#3D566E"
          d="M229.3,98.5c0,1.2,0.6,2,1.8,2c0.8,0,1.2-0.2,1.7-0.7l0.9,0.9c-0.7,0.7-1.4,1.1-2.7,1.1   c-1.8,0-3.2-0.9-3.2-3.6c0-2.3,1.2-3.6,3-3.6c1.9,0,3,1.4,3,3.4v0.6H229.3z M232.3,96.5c-0.2-0.5-0.7-0.9-1.4-0.9   c-0.7,0-1.2,0.4-1.4,0.9c-0.1,0.3-0.2,0.5-0.2,1h3.2C232.5,97,232.4,96.8,232.3,96.5z"
        />
        <path
          fill="#3D566E"
          d="M244.6,101.6v-4.3c0-1.1-0.6-1.6-1.4-1.6c-0.8,0-1.5,0.5-1.5,1.5v4.4h-1.4v-4.3c0-1.1-0.6-1.6-1.4-1.6   s-1.5,0.5-1.5,1.6v4.3h-1.4v-7.1h1.4v0.7c0.5-0.5,1.2-0.8,1.9-0.8c0.9,0,1.6,0.3,2,1c0.6-0.7,1.3-1,2.2-1c0.7,0,1.4,0.2,1.8,0.6   c0.5,0.5,0.8,1.2,0.8,2v4.5H244.6z"
        />
        <path
          fill="#3D566E"
          d="M249.4,98.5c0,1.2,0.6,2,1.8,2c0.8,0,1.2-0.2,1.7-0.7l0.9,0.9c-0.7,0.7-1.4,1.1-2.7,1.1   c-1.8,0-3.2-0.9-3.2-3.6c0-2.3,1.2-3.6,3-3.6c1.9,0,3,1.4,3,3.4v0.6H249.4z M252.4,96.5c-0.2-0.5-0.7-0.9-1.4-0.9   c-0.7,0-1.2,0.4-1.4,0.9c-0.1,0.3-0.2,0.5-0.2,1h3.2C252.6,97,252.5,96.8,252.4,96.5z"
        />
        <path
          fill="#3D566E"
          d="M260.3,101.6v-4.3c0-1.1-0.6-1.6-1.4-1.6c-0.8,0-1.5,0.5-1.5,1.6v4.3H256v-7.1h1.4v0.7   c0.5-0.5,1.2-0.8,1.9-0.8c0.7,0,1.3,0.2,1.8,0.6c0.5,0.5,0.7,1.2,0.7,2v4.5H260.3z"
        />
        <path fill="#3D566E" d="M266.2,101.6c-1.3,0-1.9-1-1.9-2v-3.9h-0.8v-1.1h0.8v-2.2h1.4v2.2h1.4v1.1h-1.4v3.8c0,0.5,0.3,0.8,0.8,0.8h0.6   v1.2H266.2z" />
        <path
          fill="#3D566E"
          d="M274.8,101.7c-1.2,0-2.1-0.2-3-1l0.9-0.9c0.6,0.6,1.3,0.7,2,0.7c0.9,0,1.6-0.3,1.6-1c0-0.5-0.3-0.8-1-0.8   l-1.1-0.1c-1.3-0.1-2.1-0.7-2.1-2c0-1.4,1.2-2.2,2.7-2.2c1.1,0,2,0.2,2.6,0.8l-0.9,0.9c-0.4-0.4-1.1-0.5-1.8-0.5   c-0.9,0-1.3,0.4-1.3,0.9c0,0.4,0.2,0.7,1,0.8l1.1,0.1c1.3,0.1,2.1,0.7,2.1,2C277.8,100.9,276.5,101.7,274.8,101.7z"
        />
        <path
          fill="#3D566E"
          d="M283.9,101.6v-0.7c-0.5,0.5-1.2,0.8-1.9,0.8c-0.7,0-1.3-0.2-1.8-0.6c-0.5-0.5-0.7-1.2-0.7-2v-4.5h1.4v4.3   c0,1.1,0.6,1.6,1.4,1.6c0.8,0,1.5-0.5,1.5-1.6v-4.3h1.4v7.1H283.9z"
        />
        <path
          fill="#3D566E"
          d="M292.8,101c-0.4,0.4-1,0.6-1.7,0.6c-0.8,0-1.3-0.2-1.9-0.8v3.6h-1.4v-10h1.4v0.8c0.5-0.6,1.1-0.8,1.9-0.8   c0.7,0,1.3,0.2,1.7,0.6c0.7,0.7,0.8,1.9,0.8,3C293.6,99.1,293.5,100.3,292.8,101z M290.7,95.7c-1.3,0-1.5,1.1-1.5,2.4   s0.2,2.4,1.5,2.4c1.3,0,1.5-1.1,1.5-2.4S292,95.7,290.7,95.7z"
        />
        <path
          fill="#3D566E"
          d="M296.7,98.5c0,1.2,0.6,2,1.8,2c0.8,0,1.2-0.2,1.7-0.7l0.9,0.9c-0.7,0.7-1.4,1.1-2.7,1.1   c-1.8,0-3.2-0.9-3.2-3.6c0-2.3,1.2-3.6,3-3.6c1.9,0,3,1.4,3,3.4v0.6H296.7z M299.7,96.5c-0.2-0.5-0.7-0.9-1.4-0.9   c-0.7,0-1.2,0.4-1.4,0.9c-0.1,0.3-0.2,0.5-0.2,1h3.2C299.9,97,299.8,96.8,299.7,96.5z M298.7,93.2h-1.1l1.1-2.3h1.6L298.7,93.2z"
        />
        <path fill="#3D566E" d="M307.2,96.2c-0.3-0.3-0.6-0.5-1.1-0.5c-0.8,0-1.4,0.6-1.4,1.6v4.3h-1.4v-7.1h1.4v0.8c0.4-0.5,1.1-0.9,1.9-0.9   c0.7,0,1.2,0.2,1.7,0.7L307.2,96.2z" />
        <path fill="#3D566E" d="M309.6,93.1v-1.5h1.5v1.5H309.6z M309.6,101.6v-7.1h1.4v7.1H309.6z" />
        <path
          fill="#3D566E"
          d="M314.5,98.5c0,1.2,0.6,2,1.8,2c0.8,0,1.2-0.2,1.7-0.7l0.9,0.9c-0.7,0.7-1.4,1.1-2.7,1.1   c-1.8,0-3.2-0.9-3.2-3.6c0-2.3,1.2-3.6,3-3.6c1.9,0,3,1.4,3,3.4v0.6H314.5z M317.5,96.5c-0.2-0.5-0.7-0.9-1.4-0.9   c-0.7,0-1.2,0.4-1.4,0.9c-0.1,0.3-0.2,0.5-0.2,1h3.2C317.7,97,317.6,96.8,317.5,96.5z"
        />
        <path
          fill="#3D566E"
          d="M325.4,101.6v-0.7c-0.5,0.5-1.2,0.8-1.9,0.8c-0.7,0-1.3-0.2-1.8-0.6c-0.5-0.5-0.7-1.2-0.7-2v-4.5h1.4v4.3   c0,1.1,0.6,1.6,1.4,1.6s1.5-0.5,1.5-1.6v-4.3h1.4v7.1H325.4z"
        />
        <path fill="#3D566E" d="M333.1,96.2c-0.3-0.3-0.6-0.5-1.1-0.5c-0.8,0-1.4,0.6-1.4,1.6v4.3h-1.4v-7.1h1.4v0.8c0.4-0.5,1.1-0.9,1.9-0.9   c0.7,0,1.2,0.2,1.7,0.7L333.1,96.2z" />
      </g>
      <g>
        <g>
          <path fill="#3D566E" d="M295.2,55.9c2.8,0,5.7,0.5,7.5,2.2l5-5c-3.1-2.9-7.3-3.8-12.4-3.8c-0.2,0-0.3,0-0.5,0v6.6    C295,55.9,295.1,55.9,295.2,55.9z" />
          <path
            fill="#3D566E"
            d="M299,62.3l-4.2-0.4v7l2.5,0.2c2.5,0.2,3.4,1.4,3.4,3c0,2.4-2.9,3.4-5.7,3.4c-0.1,0-0.1,0-0.2,0v6.9    c0,0,0.1,0,0.1,0c7.6,0,13.7-3.4,13.7-10.6C308.7,66.3,305.4,62.9,299,62.3z"
          />
        </g>
        <g>
          <path fill="#FF3333" d="M282.8,59.5c0,6,3.6,8.5,9.4,9.1l2.6,0.3v-7l-0.9-0.1c-2.9-0.2-3.4-1.6-3.4-2.8c0-1.6,1.3-3,4.3-3.1v-6.6    C288.5,49.4,282.8,53,282.8,59.5z" />
          <path fill="#FF3333" d="M286.4,72.4l-5.3,5.3c4.1,4.1,8.6,4.6,13.7,4.7v-6.9C292.4,75.4,289,75,286.4,72.4z" />
        </g>
      </g>
      <g>
        <path
          fill="#FF3333"
          d="M35.4,79.9l-13.7,0c-2.2,0-4.1,1.8-4.1,4.1c0,2.2,1.8,4.1,4.1,4.1l9.7,0v9.7c0,2.2,1.8,4.1,4.1,4.1   c1.1,0,2.1-0.5,2.9-1.2c0.7-0.7,1.2-1.8,1.2-2.9l0-13.7c0-1.1-0.5-2.1-1.2-2.9C37.5,80.3,36.5,79.9,35.4,79.9"
        />
        <path
          fill="#3D566E"
          d="M52,63l-13.7,0c-2.2,0-4.1,1.8-4.1,4.1c0,2.2,1.8,4.1,4.1,4.1l9.7,0v9.7c0,2.2,1.8,4.1,4.1,4.1   c1.1,0,2.1-0.5,2.9-1.2c0.7-0.7,1.2-1.8,1.2-2.9l0-13.7c0-1.1-0.5-2.1-1.2-2.9C54.1,63.5,53.1,63,52,63"
        />
        <path
          fill="#FF3333"
          d="M68.5,46.1l-13.7,0c-2.2,0-4.1,1.8-4.1,4.1c0,2.2,1.8,4.1,4.1,4.1l9.7,0V64c0,2.2,1.8,4.1,4.1,4.1   c1.1,0,2.1-0.5,2.9-1.2c0.7-0.7,1.2-1.8,1.2-2.9l0-13.7c0-1.1-0.5-2.1-1.2-2.9S69.7,46.1,68.5,46.1"
        />
      </g>
    </Icon>
  )
}
