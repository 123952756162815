import { Icon } from "@chakra-ui/react"

export function J1S(props) {
  return (
    <Icon viewBox="0 0 170 112" {...props}>
      <path
        d="M0 100.648H2.163L2.961 97.7921H0V95.6291H3.549L4.578 91.8701H6.972L5.943 95.6291H8.883L9.912 91.8701H12.306L11.277 95.6291H12.81V97.7921H10.689L9.891 100.648H12.81V102.811H9.303L8.274 106.57H5.88L6.909 102.811H3.969L2.94 106.57H0.546L1.575 102.811H0V100.648ZM4.557 100.648H7.497L8.295 97.7921H5.355L4.557 100.648Z"
        fill="#2FAC66"
      />
      <path d="M18.2136 106.549V94.7681L15.1686 96.4901L13.7616 94.4741L18.2976 91.8491H21.0486V106.549H18.2136Z" fill="#2FAC66" />
      <path
        d="M26.3178 94.1381C25.8418 94.1381 25.4288 93.9701 25.0788 93.6341C24.7428 93.2841 24.5748 92.8711 24.5748 92.3951C24.5748 91.9191 24.7428 91.5131 25.0788 91.1771C25.4288 90.8271 25.8418 90.6521 26.3178 90.6521C26.7798 90.6521 27.1788 90.8271 27.5148 91.1771C27.8648 91.5131 28.0398 91.9191 28.0398 92.3951C28.0398 92.8711 27.8648 93.2841 27.5148 93.6341C27.1788 93.9701 26.7798 94.1381 26.3178 94.1381ZM27.6828 107.704C27.6828 109.02 27.3608 110.035 26.7168 110.749C26.0868 111.463 25.1068 111.82 23.7768 111.82C23.3428 111.82 22.9648 111.792 22.6428 111.736C22.3208 111.68 22.0268 111.596 21.7608 111.484V109.153C21.9288 109.223 22.1458 109.279 22.4118 109.321C22.6638 109.363 22.9718 109.384 23.3358 109.384C23.8538 109.384 24.2598 109.251 24.5538 108.985C24.8618 108.733 25.0158 108.306 25.0158 107.704V95.9861H27.6828V107.704Z"
        fill="#2FAC66"
      />
      <path
        d="M40.625 104.848C40.135 105.506 39.491 106.031 38.693 106.423C37.895 106.801 36.999 106.99 36.005 106.99C35.067 106.99 34.227 106.836 33.485 106.528C32.757 106.22 32.141 105.807 31.637 105.289C31.147 104.771 30.769 104.169 30.503 103.483C30.251 102.783 30.125 102.048 30.125 101.278C30.125 100.494 30.244 99.7591 30.482 99.0731C30.734 98.3731 31.091 97.7641 31.553 97.2461C32.015 96.7281 32.582 96.3221 33.254 96.0281C33.926 95.7201 34.696 95.5661 35.564 95.5661C37.02 95.5661 38.196 96.0141 39.092 96.9101C39.988 97.7921 40.436 98.9261 40.436 100.312C40.436 100.606 40.415 100.886 40.373 101.152C40.345 101.418 40.31 101.642 40.268 101.824H32.855C32.939 102.692 33.268 103.371 33.842 103.861C34.416 104.351 35.151 104.596 36.047 104.596C36.621 104.596 37.139 104.484 37.601 104.26C38.063 104.022 38.434 103.714 38.714 103.336L40.625 104.848ZM35.564 97.7921C34.92 97.7921 34.381 97.9601 33.947 98.2961C33.513 98.6181 33.205 99.1221 33.023 99.8081H37.79C37.776 99.2901 37.573 98.8281 37.181 98.4221C36.803 98.0021 36.264 97.7921 35.564 97.7921Z"
        fill="#2FAC66"
      />
      <path
        d="M49.8911 101.992V95.9861H52.5791V101.866C52.5791 103.448 52.1381 104.701 51.2561 105.625C50.3741 106.535 49.1631 106.99 47.6231 106.99C46.0831 106.99 44.8721 106.535 43.9901 105.625C43.1221 104.701 42.6881 103.448 42.6881 101.866V95.9861H45.3761V101.992C45.3761 102.748 45.5721 103.35 45.9641 103.798C46.3561 104.246 46.9161 104.47 47.6441 104.47C48.3581 104.47 48.9111 104.246 49.3031 103.798C49.6951 103.35 49.8911 102.748 49.8911 101.992Z"
        fill="#2FAC66"
      />
      <path
        d="M55.5314 106.57V95.9861H58.1984V96.8261C58.5624 96.4761 58.9964 96.1821 59.5004 95.9441C60.0184 95.6921 60.6274 95.5661 61.3274 95.5661C61.9014 95.5661 62.4334 95.6641 62.9234 95.8601C63.4274 96.0561 63.8614 96.3501 64.2254 96.7421C64.6034 97.1341 64.8974 97.6241 65.1074 98.2121C65.3314 98.8001 65.4434 99.4861 65.4434 100.27V106.57H62.7344V100.375C62.7344 99.6751 62.5454 99.1221 62.1674 98.7161C61.8034 98.2961 61.2994 98.0861 60.6554 98.0861C59.9834 98.0861 59.4514 98.2681 59.0594 98.6321C58.6814 98.9821 58.3944 99.3251 58.1984 99.6611V106.57H55.5314Z"
        fill="#2FAC66"
      />
      <path
        d="M78.2158 104.848C77.7258 105.506 77.0818 106.031 76.2838 106.423C75.4858 106.801 74.5898 106.99 73.5958 106.99C72.6578 106.99 71.8178 106.836 71.0758 106.528C70.3478 106.22 69.7318 105.807 69.2278 105.289C68.7378 104.771 68.3598 104.169 68.0938 103.483C67.8418 102.783 67.7158 102.048 67.7158 101.278C67.7158 100.494 67.8348 99.7591 68.0728 99.0731C68.3248 98.3731 68.6818 97.7641 69.1438 97.2461C69.6058 96.7281 70.1728 96.3221 70.8448 96.0281C71.5168 95.7201 72.2868 95.5661 73.1548 95.5661C74.6108 95.5661 75.7868 96.0141 76.6828 96.9101C77.5788 97.7921 78.0268 98.9261 78.0268 100.312C78.0268 100.606 78.0058 100.886 77.9638 101.152C77.9358 101.418 77.9008 101.642 77.8588 101.824H70.4458C70.5298 102.692 70.8588 103.371 71.4328 103.861C72.0068 104.351 72.7418 104.596 73.6378 104.596C74.2118 104.596 74.7298 104.484 75.1918 104.26C75.6538 104.022 76.0248 103.714 76.3048 103.336L78.2158 104.848ZM73.1548 97.7921C72.5108 97.7921 71.9718 97.9601 71.5378 98.2961C71.1038 98.6181 70.7958 99.1221 70.6138 99.8081H75.3808C75.3668 99.2901 75.1638 98.8281 74.7718 98.4221C74.3938 98.0021 73.8548 97.7921 73.1548 97.7921Z"
        fill="#2FAC66"
      />
      <path d="M83.5309 106.549V94.7681L80.4859 96.4901L79.0789 94.4741L83.6149 91.8491H86.3659V106.549H83.5309Z" fill="#2FAC66" />
      <path
        d="M89.4091 105.037L91.1731 103.399C91.4951 103.805 91.8521 104.141 92.2441 104.407C92.6501 104.673 93.1051 104.806 93.6091 104.806C94.0291 104.806 94.3511 104.701 94.5751 104.491C94.7991 104.281 94.9111 104.022 94.9111 103.714C94.9111 103.252 94.6521 102.916 94.1341 102.706C93.6161 102.496 93.0491 102.265 92.4331 102.013C91.8311 101.747 91.2711 101.39 90.7531 100.942C90.2351 100.48 89.9761 99.7731 89.9761 98.8211C89.9761 97.8971 90.3261 97.1271 91.0261 96.5111C91.7401 95.8811 92.6431 95.5661 93.7351 95.5661C94.5471 95.5661 95.2961 95.7411 95.9821 96.0911C96.6821 96.4271 97.2281 96.8541 97.6201 97.3721L95.8351 98.9681C95.5691 98.6181 95.2611 98.3311 94.9111 98.1071C94.5611 97.8691 94.1761 97.7501 93.7561 97.7501C93.3641 97.7501 93.0631 97.8411 92.8531 98.0231C92.6431 98.1911 92.5381 98.4221 92.5381 98.7161C92.5381 99.1641 92.7971 99.4931 93.3151 99.7031C93.8331 99.9131 94.3931 100.144 94.9951 100.396C95.6111 100.648 96.1781 101.005 96.6961 101.467C97.2141 101.915 97.4731 102.608 97.4731 103.546C97.4591 104.106 97.3471 104.603 97.1371 105.037C96.9271 105.457 96.6471 105.814 96.2971 106.108C95.9471 106.388 95.5411 106.605 95.0791 106.759C94.6171 106.913 94.1411 106.99 93.6511 106.99C92.7131 106.99 91.8941 106.815 91.1941 106.465C90.5081 106.115 89.9131 105.639 89.4091 105.037Z"
        fill="#2FAC66"
      />
      <path
        d="M104.93 95.5661C105.784 95.5661 106.568 95.7201 107.282 96.0281C107.996 96.3221 108.605 96.7281 109.109 97.2461C109.627 97.7501 110.026 98.3521 110.306 99.0521C110.586 99.7521 110.726 100.494 110.726 101.278C110.726 102.062 110.586 102.804 110.306 103.504C110.026 104.204 109.627 104.813 109.109 105.331C108.605 105.835 107.996 106.241 107.282 106.549C106.568 106.843 105.784 106.99 104.93 106.99C104.076 106.99 103.292 106.843 102.578 106.549C101.864 106.241 101.248 105.835 100.73 105.331C100.226 104.813 99.8338 104.204 99.5538 103.504C99.2738 102.804 99.1338 102.062 99.1338 101.278C99.1338 100.494 99.2738 99.7521 99.5538 99.0521C99.8338 98.3521 100.226 97.7501 100.73 97.2461C101.248 96.7281 101.864 96.3221 102.578 96.0281C103.292 95.7201 104.076 95.5661 104.93 95.5661ZM104.972 104.47C105.812 104.47 106.519 104.169 107.093 103.567C107.667 102.951 107.954 102.188 107.954 101.278C107.954 100.354 107.667 99.5911 107.093 98.9891C106.519 98.3871 105.812 98.0861 104.972 98.0861C104.09 98.0861 103.355 98.3871 102.767 98.9891C102.193 99.5911 101.906 100.354 101.906 101.278C101.906 102.202 102.193 102.965 102.767 103.567C103.355 104.169 104.09 104.47 104.972 104.47Z"
        fill="#2FAC66"
      />
      <path d="M113.158 106.57V90.8201H115.825V106.57H113.158Z" fill="#2FAC66" />
      <path
        d="M125.996 101.992V95.9861H128.684V101.866C128.684 103.448 128.243 104.701 127.361 105.625C126.479 106.535 125.268 106.99 123.728 106.99C122.188 106.99 120.977 106.535 120.095 105.625C119.227 104.701 118.793 103.448 118.793 101.866V95.9861H121.481V101.992C121.481 102.748 121.677 103.35 122.069 103.798C122.461 104.246 123.021 104.47 123.749 104.47C124.463 104.47 125.016 104.246 125.408 103.798C125.8 103.35 125.996 102.748 125.996 101.992Z"
        fill="#2FAC66"
      />
      <path
        d="M132.472 102.706V98.4221H130.498V95.9861H132.472V93.3401H135.16V95.9861H138.394V98.4221H135.16V102.706C135.16 103.294 135.307 103.714 135.601 103.966C135.909 104.218 136.322 104.344 136.84 104.344C137.204 104.344 137.512 104.323 137.764 104.281C138.016 104.239 138.233 104.183 138.415 104.113V106.444C138.149 106.556 137.848 106.64 137.512 106.696C137.19 106.752 136.812 106.78 136.378 106.78C135.062 106.78 134.082 106.423 133.438 105.709C132.794 104.995 132.472 103.994 132.472 102.706Z"
        fill="#2FAC66"
      />
      <path
        d="M142.126 94.1381C141.65 94.1381 141.237 93.9701 140.887 93.6341C140.551 93.2841 140.383 92.8711 140.383 92.3951C140.383 91.9191 140.551 91.5131 140.887 91.1771C141.237 90.8271 141.65 90.6521 142.126 90.6521C142.602 90.6521 143.008 90.8271 143.344 91.1771C143.68 91.5131 143.848 91.9191 143.848 92.3951C143.848 92.8711 143.68 93.2841 143.344 93.6341C143.008 93.9701 142.602 94.1381 142.126 94.1381ZM140.782 106.57V95.9861H143.449V106.57H140.782Z"
        fill="#2FAC66"
      />
      <path
        d="M151.688 95.5661C152.542 95.5661 153.326 95.7201 154.04 96.0281C154.754 96.3221 155.363 96.7281 155.867 97.2461C156.385 97.7501 156.784 98.3521 157.064 99.0521C157.344 99.7521 157.484 100.494 157.484 101.278C157.484 102.062 157.344 102.804 157.064 103.504C156.784 104.204 156.385 104.813 155.867 105.331C155.363 105.835 154.754 106.241 154.04 106.549C153.326 106.843 152.542 106.99 151.688 106.99C150.834 106.99 150.05 106.843 149.336 106.549C148.622 106.241 148.006 105.835 147.488 105.331C146.984 104.813 146.592 104.204 146.312 103.504C146.032 102.804 145.892 102.062 145.892 101.278C145.892 100.494 146.032 99.7521 146.312 99.0521C146.592 98.3521 146.984 97.7501 147.488 97.2461C148.006 96.7281 148.622 96.3221 149.336 96.0281C150.05 95.7201 150.834 95.5661 151.688 95.5661ZM151.73 104.47C152.57 104.47 153.277 104.169 153.851 103.567C154.425 102.951 154.712 102.188 154.712 101.278C154.712 100.354 154.425 99.5911 153.851 98.9891C153.277 98.3871 152.57 98.0861 151.73 98.0861C150.848 98.0861 150.113 98.3871 149.525 98.9891C148.951 99.5911 148.664 100.354 148.664 101.278C148.664 102.202 148.951 102.965 149.525 103.567C150.113 104.169 150.848 104.47 151.73 104.47Z"
        fill="#2FAC66"
      />
      <path
        d="M159.916 106.57V95.9861H162.583V96.8261C162.947 96.4761 163.381 96.1821 163.885 95.9441C164.403 95.6921 165.012 95.5661 165.712 95.5661C166.286 95.5661 166.818 95.6641 167.308 95.8601C167.812 96.0561 168.246 96.3501 168.61 96.7421C168.988 97.1341 169.282 97.6241 169.492 98.2121C169.716 98.8001 169.828 99.4861 169.828 100.27V106.57H167.119V100.375C167.119 99.6751 166.93 99.1221 166.552 98.7161C166.188 98.2961 165.684 98.0861 165.04 98.0861C164.368 98.0861 163.836 98.2681 163.444 98.6321C163.066 98.9821 162.779 99.3251 162.583 99.6611V106.57H159.916Z"
        fill="#2FAC66"
      />
      <path
        d="M84.9978 80C62.9439 80 45 62.0561 45 39.9978C45 17.9439 62.9439 0 84.9978 0C107.052 0 124.996 17.9439 124.996 39.9978C125 62.0561 107.056 80 84.9978 80ZM84.9978 2.19875C64.1561 2.19875 47.1988 19.1561 47.1988 39.9978C47.1988 60.8395 64.1561 77.7968 84.9978 77.7968C105.839 77.7968 122.797 60.8395 122.797 39.9978C122.801 19.1561 105.844 2.19875 84.9978 2.19875Z"
        fill="#2FAC66"
      />
      <path
        d="M84.9967 60.3617C73.7685 60.3617 64.6328 51.226 64.6328 39.9978C64.6328 28.7696 73.7685 19.6339 84.9967 19.6339C96.2249 19.6339 105.361 28.7696 105.361 39.9978C105.361 51.226 96.2249 60.3617 84.9967 60.3617ZM84.9967 20.4612C74.2241 20.4612 65.4601 29.2252 65.4601 39.9978C65.4601 50.7703 74.2241 59.5344 84.9967 59.5344C95.7692 59.5344 104.533 50.7703 104.533 39.9978C104.538 29.2252 95.7692 20.4612 84.9967 20.4612Z"
        fill="#2FAC66"
      />
      <path d="M104.954 35.4187L98.2578 47.0158L104.541 50.6435L111.237 39.0464L104.954 35.4187Z" fill="#E20E18" />
      <path d="M98.6724 31.7897L91.9766 43.3868L98.2598 47.0146L104.956 35.4174L98.6724 31.7897Z" fill="white" />
      <path d="M92.395 28.1631L85.6992 39.7603L91.9824 43.388L98.6782 31.7909L92.395 28.1631Z" fill="#253574" />
      <path
        d="M92.6632 29.1766L110.227 39.3165L104.272 49.629L86.7084 39.4891L92.6632 29.1766ZM92.3889 28.1635L85.6953 39.7589L104.546 50.6421L111.24 39.0467L92.3889 28.1635Z"
        fill="#253574"
      />
      <path d="M92.6352 27.3329L79.3281 50.388L80.0408 50.7994L93.3479 27.7442L92.6352 27.3329Z" fill="#253574" />
      <path d="M77.6035 28.1135L58.7539 38.9966L65.4497 50.5938L84.2993 39.7106L77.6035 28.1135Z" fill="#253574" />
      <path
        d="M73.536 43.8954L73.7705 43.3822L74.3102 43.4486L73.9032 43.0858L74.1465 42.5948L73.6732 42.8691L73.2706 42.5151L73.3723 43.0416L72.8945 43.3159L73.4298 43.3601L73.536 43.8954Z"
        fill="#FFE910"
      />
      <path
        d="M69.3045 36.3259L69.539 35.8259L70.0787 35.879L69.6761 35.5163L69.915 35.0296L69.4461 35.2995L69.0391 34.9544L69.1452 35.4764L68.6719 35.7463L69.1983 35.7861L69.3045 36.3259Z"
        fill="#FFE910"
      />
      <path
        d="M67.4808 42.3381L67.7153 41.8382L68.2639 41.8868L67.8613 41.5241L68.1135 41.0419L67.6357 41.3206L67.2198 40.9711L67.3215 41.4975L66.8438 41.7762L67.3702 41.8028L67.4808 42.3381Z"
        fill="#FFE910"
      />
      <path
        d="M75.324 37.7946L75.5585 37.2947L76.1027 37.3478L75.7045 36.9762L75.9478 36.5072L75.4745 36.7771L75.063 36.4276L75.1648 36.9585L74.6914 37.2328L75.2134 37.2593L75.324 37.7946Z"
        fill="#FFE910"
      />
      <path
        d="M71.5902 35.6932L71.8291 35.1889L72.3732 35.2375L71.9707 34.8792L72.214 34.3925L71.7406 34.6668L71.3292 34.3085L71.4309 34.8438L70.9531 35.1225L71.484 35.1579L71.5902 35.6932Z"
        fill="#FFE910"
      />
      <path
        d="M73.786 36.2816L74.0249 35.7773L74.5691 35.826L74.1621 35.4588L74.4054 34.9765L73.932 35.2464L73.525 34.8969L73.6179 35.4322L73.1445 35.7021L73.6798 35.7375L73.786 36.2816Z"
        fill="#FFE910"
      />
      <path
        d="M75.7699 39.9669L76.0043 39.4714L76.5529 39.5201L76.1503 39.1573L76.3981 38.6662L75.9203 38.9405L75.5089 38.591L75.6062 39.1219L75.1328 39.3962L75.6637 39.4404L75.7699 39.9669Z"
        fill="#FFE910"
      />
      <path
        d="M75.1561 42.1966L75.395 41.6878L75.9436 41.7453L75.5365 41.3781L75.7843 40.8915L75.3109 41.1658L74.8951 40.8163L74.9968 41.3472L74.5234 41.6215L75.0543 41.6569L75.1561 42.1966Z"
        fill="#FFE910"
      />
      <path
        d="M71.3485 44.3953L71.583 43.8866L72.136 43.9397L71.729 43.5725L71.9767 43.0858L71.4989 43.3601L71.0875 43.0106L71.1848 43.5415L70.707 43.8158L71.2379 43.8556L71.3485 44.3953Z"
        fill="#FFE910"
      />
      <path
        d="M69.2074 43.7715L69.4463 43.276L69.986 43.3203L69.5878 42.9619L69.8356 42.4709L69.3578 42.7452L68.9508 42.4001L69.0437 42.9265L68.5703 43.2008L69.1056 43.2407L69.2074 43.7715Z"
        fill="#FFE910"
      />
      <path
        d="M66.9071 40.25L67.1416 39.75L67.6902 39.7987L67.2832 39.4315L67.5353 38.9449L67.0531 39.2192L66.6417 38.8741L66.739 39.405L66.2656 39.6748L66.7965 39.7147L66.9071 40.25Z"
        fill="#FFE910"
      />
      <path
        d="M67.454 38.091L67.6885 37.5911L68.2326 37.6442L67.83 37.2814L68.0734 36.7992L67.6044 37.0691L67.1841 36.7151L67.2903 37.2505L66.8125 37.5247L67.3434 37.5646L67.454 38.091Z"
        fill="#FFE910"
      />
      <path d="M77.3689 27.3298L76.6562 27.7412L89.966 50.7937L90.6786 50.3822L77.3689 27.3298Z" fill="#253574" />
      <path
        d="M54.8633 25.4914L57.7212 20.4922L59.1679 21.3195L57.3054 24.58L59.3404 25.7435L60.9242 22.9741L62.3709 23.8014L60.7871 26.5708L64.4369 28.659L63.4415 30.3976L54.8633 25.4914Z"
        fill="#2FAC66"
      />
      <path
        d="M61.1211 16.4707L63.4393 14.5373C65.1072 13.1482 66.8502 13.1438 68.027 14.555C68.7924 15.4752 68.9207 16.5282 68.4385 17.5368L73.6146 18.9172L71.8052 20.4258L67.2219 19.0454L66.4299 19.7046L68.987 22.7749L67.4475 24.0579L61.1211 16.4707ZM63.7269 16.4707L65.3593 18.4349L66.2264 17.7138C66.8768 17.1697 66.9387 16.4751 66.4255 15.8601C65.9477 15.285 65.2443 15.2098 64.5895 15.754L63.7269 16.4707Z"
        fill="#2FAC66"
      />
      <path
        d="M75.3273 8.04288L77.8668 7.37927L83.9764 15.9973L81.9148 16.5326L80.3531 14.2852L76.5263 15.2806L76.2608 18.0058L74.1992 18.5411L75.3273 8.04288ZM79.3223 12.7899L77.0748 9.53821L76.6988 13.4712L79.3223 12.7899Z"
        fill="#2FAC66"
      />
      <path
        d="M87.3801 6.42371L89.8974 6.94574L92.7686 14.8382L94.2241 7.84382L96.1884 8.25084L94.1755 17.9262L91.6582 17.4042L88.787 9.51169L87.3315 16.5061L85.3672 16.0991L87.3801 6.42371Z"
        fill="#2FAC66"
      />
      <path
        d="M103.878 21.2222L104.369 23.1555C102.9 23.5625 101.259 23.275 99.8522 22.1645C97.4013 20.2356 97.2111 17.0326 98.9586 14.8162C100.706 12.5997 103.86 12.0335 106.311 13.9623C107.718 15.0684 108.373 16.6079 108.342 18.1121L106.342 18.0988C106.395 17.1167 106.037 16.1345 105.152 15.4356C103.688 14.2853 101.75 14.6038 100.578 16.0903C99.4098 17.5768 99.5514 19.5366 101.016 20.6913C101.901 21.3859 102.936 21.5053 103.878 21.2222Z"
        fill="#2FAC66"
      />
      <path
        d="M112.757 21.2752L115.544 26.3142L114.088 27.1194L112.27 23.8367L110.222 24.9693L111.766 27.7609L110.31 28.566L108.766 25.7745L106.545 27.0043L108.364 30.2914L106.908 31.0966L104.121 26.0576L112.757 21.2752Z"
        fill="#2FAC66"
      />
      <path
        d="M62.1205 47.4833L63.4654 50.1864C64.4343 52.133 64.0317 53.8274 62.3904 54.6459C61.3153 55.1812 60.2624 55.0573 59.3953 54.3539L56.8559 59.0699L55.8074 56.9596L58.2096 52.8187L57.7495 51.8985L54.1749 53.677L53.2812 51.8808L62.1205 47.4833ZM61.5232 50.0227L59.236 51.1597L59.7404 52.1684C60.1164 52.9249 60.78 53.1461 61.5011 52.7922C62.1692 52.4604 62.4036 51.7924 62.0276 51.0314L61.5232 50.0227Z"
        fill="#2FAC66"
      />
      <path
        d="M66.537 55.2961L70.6779 59.2955L69.5232 60.4944L66.8245 57.8886L65.1965 59.5742L67.4926 61.7906L66.3379 62.9896L64.0418 60.7775L62.2766 62.6047L64.9753 65.2104L63.8206 66.4093L59.6797 62.41L66.537 55.2961Z"
        fill="#2FAC66"
      />
      <path d="M72.416 60.3661L74.2166 61.2421L70.6907 68.4887L74.0662 70.13L73.2699 71.7669L68.0938 69.2496L72.416 60.3661Z" fill="#2FAC66" />
      <path
        d="M81.7583 63.6355L84.3729 63.8523L87.284 74.0055L85.1604 73.8285L84.4305 71.1918L80.4931 70.8644L79.3384 73.3463L77.2148 73.1693L81.7583 63.6355ZM83.9527 69.4398L82.9086 65.6307L81.254 69.2186L83.9527 69.4398Z"
        fill="#2FAC66"
      />
      <path
        d="M88.9336 63.9275L91.3447 63.0426L97.9498 68.2232L95.49 61.5208L97.3702 60.8306L100.772 70.1034L98.3612 70.9882L91.7561 65.8033L94.2159 72.5057L92.3357 73.1958L88.9336 63.9275Z"
        fill="#2FAC66"
      />
      <path
        d="M108.98 59.5256L110.958 59.269C111.183 60.7776 110.701 62.3702 109.431 63.6355C107.224 65.8343 104.021 65.6396 102.03 63.6399C100.039 61.6403 99.8576 58.4373 102.065 56.2385C103.335 54.9732 104.941 54.5087 106.427 54.7211L106.175 56.7075C105.206 56.5349 104.189 56.7738 103.388 57.5702C102.07 58.8841 102.154 60.8484 103.49 62.1889C104.826 63.5293 106.79 63.6222 108.108 62.3083C108.905 61.5075 109.148 60.49 108.98 59.5256Z"
        fill="#2FAC66"
      />
      <path
        d="M105.613 52.2303L107.98 46.979L109.498 47.6603L107.954 51.0845L110.09 52.049L111.4 49.1424L112.917 49.8237L111.608 52.7303L113.922 53.7743L115.466 50.3501L116.983 51.0359L114.616 56.2828L105.613 52.2303Z"
        fill="#2FAC66"
      />
    </Icon>
  )
}
